import { getStoryblokApi, storyblokEditable } from "@storyblok/react";

import { useState, useEffect } from "react";
import ArticleTeaser from "../components/ArticleTeaser";

const Blog = ({ blok }: any) => {
  const [articles, setArticles] = useState<any>([]);
  useEffect(() => {
    const getArticles = async () => {
      const storyblokApi = getStoryblokApi();
      const { data } = await storyblokApi.get(`cdn/stories`, {
        version: "draft", // or 'published'
        starts_with: "blog/",
        is_startpage: false,
      });

      setArticles((prev: any) =>
        data.stories.map((article: any) => {
          article.content.slug = article.slug;
          return article;
        })
      );
    };
    getArticles();
  }, []);
  return (
    <div className="my-10">
      <p className="text-3xl">{blok.title}</p>
      <div
        className="grid w-full grid-cols-1 gap-6 mx-auto md:grid-cols-2 xl:grid-cols-3 lg:px-24 md:px-16 px-6"
        {...storyblokEditable(blok)}
      >
        {articles[0] &&
          articles.map((article: any) => (
            <ArticleTeaser
              article={article.content}
              key={article.uuid}
              folder="blog"
            />
          ))}
      </div>
    </div>
  );
};
export default Blog;
