import { render } from "storyblok-rich-text-react-renderer";
import Button from "./Button";

const ArticleTeaser = ({ article, folder = "blog" }: any) => {
  return (
    <div className="flex flex-col justify-between p-4 md:p-8 bg-grayBackground">
      <div>
        <img className="mb-8" src={article.image.filename} alt="blog" />
        <h3 className="mx-auto mb-8 font-semibold leading-none tracking-tighter text-neutral-600">
          {article.title}
        </h3>
        <div className="mx-auto text-base leading-relaxed text-gray-500">
          {render(article.teaser)}
        </div>
      </div>
      <div className="mt-4">
        <Button
          text="En savoir plus"
          outlined={false}
          link={`/${folder}/${article.slug}`}
        />
      </div>
    </div>
  );
};
export default ArticleTeaser;
